import React, { useMemo, useState } from "react";
import {
  FaOverview,
  FaCareer,
  FaCertification,
  FaCommunity,
  FaContact,
  FaFeedback,
  FaEvents,
  FaHelp,
  FaResources,
  FaSettings,
  FaLogout,
} from "../images/svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { handleLogout, isScreenSmall } from "../utility/utils";
import Logo from "../images/svg/logo.svg";
import useJwt from "../Auth/jwt/useJwt";
import { useDispatch, useSelector } from "react-redux";
import { ChevronLeft } from "@mui/icons-material";
import { handleSidebarOpen } from "../redux/navbar";
import { CircularProgress } from "@mui/material";

const navigations = [
  {
    Icon: FaOverview,
    text: "Overview",
    link: "/dashboard/overview",
    id: 1,
  },
  {
    Icon: FaResources,
    text: "SOS Resources",
    link: "/dashboard/resources",
    id: 3,
  },
  {
    Icon: FaCommunity,
    text: "Community",
    link: "/dashboard/community",
    id: 6,
  },
  {
    Icon: FaContact,
    text: "Contact",
    link: "/dashboard/contact",
    id: 9,
  },
  {
    Icon: FaFeedback,
    text: "Feedback",
    link: "/dashboard/feedback",
    id: 10,
  },
  {
    Icon: FaSettings,
    text: "Settings",
    link: "/dashboard/settings",
    id: 7,
  },

  {
    Icon: FaCareer,
    text: "Career",
    link: "/dashboard/career",
    id: 2,
  },
  {
    Icon: FaCertification,
    text: "Certifications",
    link: "/dashboard/certifications",
    id: 4,
  },
  {
    Icon: FaEvents,
    text: "Events",
    link: "/dashboard/events",
    id: 5,
  },

  {
    Icon: FaHelp,
    text: "Help",
    link: "/dashboard/help",
    id: 8,
  },
];

const adminNavigations = [
  {
    Icon: FaOverview,
    text: "Overview",
    link: "/admin",
    id: 1,
  },
  {
    Icon: FaCommunity,
    text: "User Insights",
    link: "/admin/users",
    id: 2,
  },
];

const Sidebar = () => {
  const location = useLocation();
  const userData = useJwt.getUserData();
  const [loading, _loading] = useState(false);
  const { isSidebarOpen, currentBreakPoint } = useSelector(
    (state) => state.navbar
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPath = location.pathname;

  const onLogout = () => {
    _loading(true);
    handleLogout().then(() => {
      useJwt.setUserData({});
      _loading(false);
      navigate("/login");
    });
  };

  const handleCollapse = () => {
    dispatch(handleSidebarOpen());
  };

  const handleLinkClick = (link) => {
    if (isScreenSmall(currentBreakPoint)) {
      handleCollapse();
    }
    navigate(link);
  };

  const routes = useMemo(
    () => (userData?.is_admin ? adminNavigations : navigations),
    [userData]
  );

  return (
    <aside
      id="default-sidebar"
      className={`fixed top-0 left-0 z-[300] flex flex-row h-screen  lg:translate-x-0 transition-all ease-in   ${
        isSidebarOpen ? " bg-gray-50 " : " bg-transparent "
      }`}
      aria-label="Sidebar"
    >
      <div
        className={`h-full overflow-y-auto sidebar-content-wrapper transition-all ease-in ${
          isSidebarOpen ? " w-[260px] ps-5 pr-0 py-[27px] " : " w-0 p-0"
        }`}
      >
        <div className="flex justify-center items-center">
          <Link to={userData?.is_admin ? "#" : "/"}>
            <img src={Logo} loading="lazy" alt="logo" className="h-[120px]" />
          </Link>
        </div>
        <ul className="space-y-2 font-medium mt-6">
          {routes.map(({ Icon, text, id, link }) => {
            return (
              <li key={"nav-link" + id}>
                <button
                  onClick={() => handleLinkClick(link)}
                  className={`flex items-center ${
                    currentPath == link ? " active " : ""
                  } py-3 px-6 text-gray-900 cursor-pointer rounded-lg w-full d group`}
                >
                  <Icon />
                  <span className="ms-3">{text}</span>
                </button>
              </li>
            );
          })}
          <li key={"logoutbutton"}>
            <button
              className={
                "flex items-center p-5 px-7 text-gray-900 rounded-lg  d group"
              }
              onClick={onLogout}
            >
              {loading ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                <FaLogout />
              )}
              <span className="ms-3">Logout</span>
            </button>
          </li>
        </ul>
      </div>
      <button
        onClick={handleCollapse}
        className={` opacity-100 h-10 min-w-10 bg-primary-green rounded-full border border-primary-green p-1 relative right-2 flex justify-center items-center mt-8 md:mt-3 xs:mt-3 sm:mt-3 lg:mt-8 mr-[-1.25rem] ${isSidebarOpen ? " sidebar-open-button " : " sidebar-close-button right-4"
        }`}
      >
        <ChevronLeft
          fontSize="medium"
          className={`svg-white transition-transform ${
            isSidebarOpen ? " " : " rotate-180"
          } `}
        />
      </button>
    </aside>
  );
};

export default Sidebar;
