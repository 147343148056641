import useJwt from "../Auth/jwt/useJwt";
const userData = useJwt.getUserData();

export const privateRouteChecks = () => {

  const userHasData = typeof userData === "object" && Object.keys(userData || {}).length > 0;

  if (
    userHasData &&
    !userData.is_admin &&
    !userData.is_profile_completed
  ) {
    return { redirectTo: "/setup-profile" };
  }

  return null;
};
