// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import useJwt from '../../Auth/jwt/useJwt';

export const getCountries = createAsyncThunk('locations/getCountries', async ({ assigned }) => {
    try {
        // Initialize the query string
        let query = '';

        // Append assigned to the query if it's true
        if (assigned == true) {
            query += 'assigned=true';
        }

        const response = await useJwt.getCountries(query);

        return {
            data: response?.data?.data || []
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
});

export const countries = createSlice({
    name: 'countries',
    initialState: {
        countries: {
            data: [],
            loading: false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCountries.pending, (state) => {
                state.countries.data = [];
                state.countries.loading = true;
            })
            .addCase(getCountries.fulfilled, (state, action) => {
                state.countries.loading = false;
                state.countries.data = action.payload.data;
            })
    }
});

export default countries.reducer;
