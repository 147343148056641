import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";
export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  // ** Create a new Axios instance
  axiosInstance = axios.create();

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    this.axiosInstance.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken();

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Response Interceptor
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error;
        const originalRequest = config;
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken()
              .then((r) => {
                this.isAlreadyFetchingAccessToken = false;

                // ** Update accessToken in localStorage
                const isRemember = localStorage.getItem(
                  this.jwtConfig.rememberme
                );
                const tokens = r?.data?.data;
                if (tokens?.access?.token)
                  this.setToken(tokens.access.token, Boolean(isRemember));
                if (tokens?.refresh?.token)
                  this.setRefreshToken(
                    tokens.refresh.token,
                    Boolean(isRemember)
                  );

                this.onAccessTokenFetched(tokens.access.token);
              })
              .catch(() => {
                localStorage.clear();
                sessionStorage.clear();
                location.reload();
              });
          }

          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axiosInstance(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return (
      localStorage.getItem(this.jwtConfig.storageTokenKeyName) ||
      sessionStorage.getItem(this.jwtConfig.storageTokenKeyName)
    );
  }

  setUserData = (data, isRememberMe = false) => {
    if (isRememberMe) {
      isRememberMe = localStorage.getItem(jwtDefaultConfig.rememberme);
    }

    if (isRememberMe == "true")
      localStorage.setItem(jwtDefaultConfig.userData, JSON.stringify(data));
    else
      sessionStorage.setItem(jwtDefaultConfig.userData, JSON.stringify(data));
  };

  getUserData = () => {
    let userData =
      localStorage.getItem(jwtDefaultConfig.userData) ||
      sessionStorage.getItem(jwtDefaultConfig.userData);
    userData = userData ? JSON.parse(userData) : {};
    return userData;
  };

  setLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  getLocalStorage = (key) => {
    let data = localStorage.getItem(key);
    data = data ? JSON.parse(data) : {};
    return data;
  };

  getRefreshToken() {
    return (
      localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName) ||
      sessionStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    );
  }

  setToken(value, isRemember) {
    if (isRemember)
      localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
    else sessionStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value, isRemember) {
    if (isRemember)
      localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
    else
      sessionStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  // questions
  getQuestions() {
    return this.axiosInstance.get(this.jwtConfig.questions);
  }

  submitQuestions(payload) {
    return this.axiosInstance.post(this.jwtConfig.updateQuestions, payload);
  }

  updateProfile(payload) {
    return this.axiosInstance.post(this.jwtConfig.updateProfile, payload);
  }

  updateProfileImage(payload) {
    return this.axiosInstance.post(this.jwtConfig.updateProfileImage, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getProfile() {
    return this.axiosInstance.get(this.jwtConfig.profile);
  }

  recoverPassword(payload) {
    return this.axiosInstance.post(this.jwtConfig.recoverPassword, payload);
  }

  // auth
  login(payload) {
    return this.axiosInstance.post(this.jwtConfig.loginEndpoint, payload);
  }

  facebookLogin(payload) {
    return this.axiosInstance.post(this.jwtConfig.facebookEndpoint, payload);
  }

  loginWithGoogle(payload) {
    return this.axiosInstance.post(this.jwtConfig.googleEndpoint, payload);
  }

  register(payload) {
    return this.axiosInstance.post(this.jwtConfig.registerEndpoint, payload);
  }

  verifyEmail(query) {
    return this.axiosInstance.get(`${this.jwtConfig.verifyEmail}?${query}`);
  }

  submitResetPassword(payload) {
    return this.axiosInstance.post(
      `${this.jwtConfig.resetPasswordSubmit}`,
      payload
    );
  }

  logout(payload) {
    return this.axiosInstance.post(this.jwtConfig.logoutEndpoint, payload);
  }

  refreshToken() {
    return this.axiosInstance.post(this.jwtConfig.refreshEndpoint, {
      refresh_token: this.getRefreshToken(),
    });
  }

  getScaleData() {
    return this.axiosInstance.get(this.jwtConfig.scaleData);
  }

  getLocations() {
    return this.axiosInstance.get(this.jwtConfig.locations);
  }

  getCountries(query) {
    return this.axiosInstance.get(`${this.jwtConfig.getCountries}?${query}`);
  }

  getStates(query) {
    return this.axiosInstance.get(`${this.jwtConfig.getStates}?${query}`);
  }

  getCities(query) {
    return this.axiosInstance.get(`${this.jwtConfig.getCities}?${query}`);
  }

  getDashboardLocationFilters() {
    return this.axiosInstance.get(this.jwtConfig.dashboardLocationFilters);
  }

  getMajors() {
    return this.axiosInstance.get(this.jwtConfig.majors);
  }

  saveHouse(payload) {
    return this.axiosInstance.post(this.jwtConfig.saveHouse, payload);
  }

  saveSelectedCareers(payload) {
    return this.axiosInstance.post(this.jwtConfig.saveSelectedCareers, payload);
  }

  setUpProfile(payload) {
    return this.axiosInstance.post(this.jwtConfig.setupProfile, payload);
  }

  globeData() {
    return this.axiosInstance.get(this.jwtConfig.globeData);
  }

  fetchAdminUsers(payload) {
    // return this.axiosInstance.get(`${this.jwtConfig.adminUsers}${query}`);
    return this.axiosInstance.post(`${this.jwtConfig.adminUsers}`, payload);
  }

  deleteUser(id) {
    return this.axiosInstance.delete(`${this.jwtConfig.deleteUser}/${id}`);
  }

  adminDashboardData(payload) {
    return this.axiosInstance.post(`${this.jwtConfig.adminDashboard}`, payload);
  }

  userInsightsData(payload) {
    return this.axiosInstance.post(this.jwtConfig.userInsights, payload);
  }

  exportUsers(payload) {
    return this.axiosInstance.post(`${this.jwtConfig.exportUsers}`, payload, {
      responseType: "blob",
    });
  }

  deleteUsers(payload) {
    return this.axiosInstance.post(`${this.jwtConfig.deleteUsers}`, payload);
  }
}
