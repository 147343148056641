// ** Reducers Imports
import { combineReducers } from 'redux';
import industries from './careerIndustries';
import questions from './questions';
import summary from './careerSummary';
import careers from './careers';
import profiler from './profiler'
import navbar from './navbar';
import common from './common';
import countries from './location/countries';
import countryStates from './location/countryStates';
import stateCities from './location/stateCities';

// Combine location reducers
const locationReducer = combineReducers({
    countries,
    countryStates,
    stateCities,
});

// Combine all reducers
const rootReducer = combineReducers({
    questions,
    industries,
    summary,
    careers,
    profiler,
    navbar,
    common,
    location: locationReducer,
});

export default rootReducer;
