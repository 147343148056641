// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import useJwt from '../../Auth/jwt/useJwt';

export const getStates = createAsyncThunk('locations/getStates', async ({countryId, assigned}) => {
    try {
        
        // Initialize the query string
        let query = '';

        // Append countryId to the query if it's provided
        if (countryId) {
            query += `countryId=${countryId}`;
        }

        // Append assigned to the query if it's true
        if (assigned == true) {
            if (query) query += '&'; // Add an ampersand if there's already a parameter in the query
            query += 'assigned=true';
        }

        const response = await useJwt.getStates(query);
        
        return {
            data: response?.data?.data || []
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
});

export const countries = createSlice({
    name: 'countries',
    initialState: {
        countryStates: {
            data: [],
            loading: false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStates.pending, (state) => {                
                state.countryStates.data = [];
                state.countryStates.loading = true;
            })
            .addCase(getStates.fulfilled, (state, action) => {
                state.countryStates.loading = false;
                state.countryStates.data = action.payload.data;
            })
        }
    });

export default countries.reducer;
