// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import useJwt from '../../Auth/jwt/useJwt';

export const getCities = createAsyncThunk('locations/getCities', async ({stateId, assigned}) => {
    try {
        // Initialize the query string
        let query = '';

        // Append stateId to the query if it's provided
        if (stateId) {
            query += `stateId=${stateId}`;
        }

        // Append assigned to the query if it's true
        if (assigned == true) {
            if (query) query += '&'; // Add an ampersand if there's already a parameter in the query
            query += 'assigned=true';
        }
        const response = await useJwt.getCities(query);

        return {
            data: response?.data?.data || []
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
});

export const countries = createSlice({
    name: 'countries',
    initialState: {
        stateCities: {
            data: [],
            loading: false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCities.pending, (state) => {
                state.stateCities.data = [];
                state.stateCities.loading = true;
            })
            .addCase(getCities.fulfilled, (state, action) => {
                state.stateCities.loading = false;
                state.stateCities.data = action.payload.data;
            })
    }
});

export default countries.reducer;
